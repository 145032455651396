import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car/ExoticRental16Car";


export default function ExoticCarRentalForWedding() {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent an exotic car for wedding?",
            answer: "at monaco experiences, exotic car rental for wedding prices vary based on the model and rental duration. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "why should i rent wedding cars from monaco experiences?",
            answer: "we are the best exotic and luxury car rental service provider, offering unparalleled cars, professional service, and unforgettable experiences.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "where can i find exotic car rentals for wedding near me?",
            answer: "monaco experiences provides exotic car rentals for wedding  in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Exotic, Luxury & Vintage Car Rental for Weddings in NJ,NY,LA
                </title>
                <meta name="description" content="We specialize in providing luxurious cars for wedding baraats. With a fleet of exotic and Vintage cars, we ensure a memorable entrance for the groom and bride. Get customised quote now.." />
                <meta name="keywords" content="wedding car rental, wedding cars,  baraat car rental, bidai car hire, luxury cars for wedding" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-for-wedding" />
            </Helmet>

            <div class="font-montserrat mt-100">
                <div
                    className="section-container"
                    style={{
                        backgroundImage: `url(../../../../monaco_assets/exotic-car-rental-for-wedding.svg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '85vh',
                        position: 'relative',
                    }}
                >
                    <div
                        className="section-content text-center"
                        style={{
                            position: 'absolute',
                            top: '55%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffffff',
                            display: 'flex',
                            minWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental for wedding</h1>
                        <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                        <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                    </div>
                </div>

            </div>


            <div class="container mt-30">

                <div class="row" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mb-10">
                        <h2 class="font-montserrat fs-30 fw-600 mb-20" style={{ color: '#fff', textAlign: 'center', lineHeight: '1' }}>
                            exotic car for exotic people : make your wedding a showstopper!
                        </h2>
                    </div>
                    <div class="col-lg-4">
                        <img src="../../../../monaco_assets/wedding-5.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>
                    <div class="col-lg-8">
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            <span style={{ fontWeight: 700 }}>ditch the ordinary limo!</span> and arrive in unparalleled style with a luxury car rental for weddings from monaco experiences because your love story is <span style={{ fontWeight: 700 }}>unique</span>, so shouldn't your wedding entrance be too?
                        </p>

                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            imagine pulling up to the venue in a sleek ferrari, turning heads and setting the tone for an unforgettable celebration. with the <span style={{ fontWeight: 700 }}>roaring sound of a powerful v10</span> engine echoing through the air, our highly maintained fleet boasts an array of <span style={{ fontWeight: 700 }}>head-turning options</span>.
                        </p>

                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            it's more than a mode of transport; it's a symbol of <span style={{ fontWeight: 700 }}>your fearless</span> love story. our dedicated team guarantees perfection in every aspect, from selecting the perfect luxury car rental to ensuring a <span style={{ fontWeight: 700 }}>memorable entrance</span>.
                        </p>

                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            make your grand entrance a <span style={{ fontWeight: 700 }}>moment</span> etched in memory. choose monaco experiences for wedding car rental and let your wedding be the <span style={{ fontWeight: 700 }}>ultimate</span> showstopper.
                        </p>

                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            for more about our exotic car rental for weddings, check ot cars fleet here !
                        </p>

                    </div>
                    <div class="col-lg-12 mt-20">
                        <img src="../../../../monaco_assets/wedding-4.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-12 mt-20">
                        <h2 class="font-montserrat fs-30" style={{ color: '#fff', textAlign: 'center' }}>
                            you deserve it! choose your exotic ride from our 12+ luxury car rentals
                        </h2>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            your wedding day is one of the <span style={{ fontWeight: 700 }}>most cherished</span> moments of your life. it's a day filled with love, joy, and dreams coming true. at monaco experiences, we understand how important <span style={{ fontWeight: 700 }}>this day</span> is to you, and we're here to make it even more <span style={{ fontWeight: 700 }}>magical</span>.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            our luxury car rental for wedding services offers over 12+ <span style={{ fontWeight: 700 }}>exquisite</span> vehicles to choose from. each car in our fleet is ready make your dream into reality. with our wedding car rental, you'll add a touch of <span style={{ fontWeight: 700 }}>elegance</span> and <span style={{ fontWeight: 700 }}>glamour to your special day</span>.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            <span style={{ fontWeight: 700 }}>don't just dream about it</span>—live it. you deserve nothing but the best on your wedding day. let us help you create unforgettable memories with an exotic car rental for wedding that matches the beauty of your love story. choose monaco experiences for your luxury car rental for wedding, and make your day <span style={{ fontWeight: 700 }}>truly extraordinary.</span>
                        </p>
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/wedding-1.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/wedding-2.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/wedding-3.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rental
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car extraText={`RENTAL FOR WEDDING`} />
            </div>


            <DriveTheDream />

            <Contact />

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>

        </>
    )
}
