import React from 'react'
import GrowWithUs from '../growWithUs/index'
import ExoticRentalCar from '../exoticRentalCar'
import Contact from '../contact/Contact'
import DriveTheDream from '../driveTheDream'
import LuxuryMansion from '../luxuryMansion'
import ExoticCarBlog from '../exoticCarBlog'
import StoriesFromCustomer from '../storiesFromCustomer'
import Hero from '../hero'
import { Helmet } from 'react-helmet'
import Experience from '../experience'
import WhyChooseUs from '../whyChooseUs'
import ExoticExperiencesNationwide from '../exoticExperiencesNationwide'
import AskQuestion from '../askQuestion'

export default function Home() {

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Monaco Experiences | Exotic & Vintage Car Rentals | NJ, NY, LA..
        </title>
        <meta name="description" content="Get a customised quote & Join over 1000+ delighted enthusiasts who have experienced driving Lamborghini,McLaren,Ferrari,Rolls Royce & more in NJ, NY, LA..." />
        <meta name="keywords" content=" exotic car rental ny, luxury car rental ny, exotic car rental nj, luxury car rental nj, exotic car rental la, luxury car rental la " />
        <link rel="canonical" href="https://monacoexperiences.com/" />
      </Helmet>

      <Hero />
      <WhyChooseUs />
      <ExoticExperiencesNationwide />

      <GrowWithUs />

      <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
        <div class="container section-heading text-center">
          <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
            our ultimate exotic car rental fleet
          </h2>
          <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
            classy and luxury car rental : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
          </p>
        </div>
        <ExoticRentalCar />
      </div>

      <div class='pt-10' style={{ background: '#111' }}>
        <DriveTheDream />
      </div>
      <LuxuryMansion />
      <Contact />
      <div style={{ background: '#111' }}>
        <ExoticCarBlog />
      </div>
      <StoriesFromCustomer />
      <div style={{ background: '#111' }}>
        <AskQuestion />
      </div>

      {/* <Experience /> */}
    </div>
  )
}
