import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function BentleyGTC() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/bentley-gtc-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/bentley-gtc-2.png",
        "../../../../monaco_assets/bentley-gtc-3.png",
        "../../../../monaco_assets/bentley-gtc-4.png",
        "../../../../monaco_assets/bentley-gtc-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a bentley?",
            answer: "at monaco experiences, bentley rental prices vary based on the season and rental duration. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "do you offer delivery and pickup services for the bentley?",
            answer: "yes, we offer delivery and pickup services for your convenience. please contact us to arrange these services and discuss any associated fees.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "what are the requirements for bentley rental ?",
            answer: "to rent a bentley from monaco experiences, you need a valid driver’s license, proof of insurance, and a security deposit. age restrictions may apply. fill up the contact form or call us for more details",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "are there any mileage restrictions when hiring the bentley?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the bentley is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "where can i find bentley rental near me?",
            answer: "bentley rentals available at monaco experiences across various locations like la, new york, boston, philadelphia, and beyond. additionally, reach out to us for delivery options to your preferred location, available upon request.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Bentley Rental in NJ,NY,LA | Monaco Experiences Luxury Car Rental
                </title>
                <meta name="description" content="Indulge in luxury with a Bentley GTC rental from Monaco Experiences for weddings or any event. Enjoy elegance and comfort. Contact us for a customised quote." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/bentley-gtc-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">
                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo2.png" alt="Image" style={{ height: '40px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>BENTLEY GTC</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                4.l v8  |  626 horsepower  |  actual msrp : $ 284,750
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <div class=" font-montserrat">
                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>
                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            bentley rentals : always luxurious, always glamorous!
                        </h1>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            <span class="fw-700">we only live once! why think?</span> drive our bentley rental today and unlock the luxury and power. with years of experience in providing unparalleled service, we understand what it takes to deliver a truly exceptional rental experience. our carefully curated fleet of bentley models ensures that you have access to the finest vehicles, each meticulously maintained to perfection.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            at bentley rentals, our expertise goes beyond just providing cars - it's about crafting <span class="fw-700">unforgettable moments</span>. whether you're planning a wedding, a special event, or simply want to indulge in a taste of <span class="fw-700">luxury</span>, our team is here to make it happen seamlessly. we prioritize your satisfaction above all else, ensuring that every aspect of your rental experience exceeds your expectations.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            why compromise when you can drive a bentley and turn heads everywhere you go? with our unparalleled experience and expertise, <span class="fw-700">we promise to elevate your journey</span> to new heights of luxury and sophistication. experience the pinnacle of elegance.
                        </p>

                        <p class="font-montserrat fw-700 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            steal the spotlight! rent a bentley! book now and be the centre of attention wherever you drive 🎯
                        </p>
                    </div >
                </div >
            </div >


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury cars rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
