import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function RollsRoyceWraith() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/rolls-royce-wraith-1.png')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/rolls-royce-wraith-2.png",
        "../../../../monaco_assets/rolls-royce-wraith-3.png",
        "../../../../monaco_assets/rolls-royce-wraith-4.png",
        "../../../../monaco_assets/rolls-royce-wraith-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a rolls royce?",
            answer: "the rental cost varies based on the duration and specifics of your rental agreement. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what are the requirements to rent a rolls royce?",
            answer: "to rent a rolls royce , you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "are there any mileage restrictions when hiring the rolls royce ?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can i rent a rolls royce for my wedding?",
            answer: "absolutely! our rolls royce rental for wedding adds elegance to your special day. rent through monaco experiences for an unforgettable entrance.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the rolls royce is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "where can i find rolls royce rentals near me?",
            answer: "monaco experiences offers rolls royce rentals near you in various locations, including la, new york, philadelphia and more. we can transport / deliver the car to your location. simply reach out to us or call directly for more details.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Rolls Royce Hire NYC,New Jersey by Monaco Experiences
                </title>
                <meta name="description" content="Request your custom quote today! Elevate your Wedding/Event with a Rolls Royce rental.Experience timeless luxury. " />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/rolls-royce-wraith-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo5.png" alt="Image" style={{ height: '50px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>ROLLS ROYCE WRAITH</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                6.6.l twin turbo v12  |  624 horsepower  |  0-60  4.4sec  |  actual msrp : $343,000 
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>

                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            rolls royce wraith rental 
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            the rolls-royce wraith is a <span className="fw-600">masterpiece of luxury and class</span>, making it the ideal choice for those seeking a lifetime of memories. you can rent a rolls-royce in nj, ny, la and experience the ultimate in <span className="fw-600">luxury and power</span>, whether you want to make a grand entry or just enjoy the height of car luxury. we at monaco experiences know that your big day should only have the best. that's why our rolls-royce rental for weddings is designed to <span className="fw-600">make your event even more glamorous.</span>
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            there's more to the wraith than just a car. it is the best choice for any <span className="fw-600">high-class event</span> because of its iconic style, <span className="fw-600">superior comfort and the prestige</span> that comes with the rolls-royce name. picture riding to your wedding in a car that combines classic style with modern luxury. with monaco experiences, you can make your dream of coming in a rolls-royce wraith come true. this will make sure that your big day is <span className="fw-600">just as special</span> as the car you're riding in. if you want a wedding or an event that stands out, we provide you with a rolls-royce that will do just that.
                        </p>

                        <p class="font-montserrat fw-600 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            be sure to have a look at our rolls-royce dawn as well! 
                        </p>
                    </div>
                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury cars rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
