import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function LamborghiniHuracan() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/lamborghini-huracan-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/lamborghini-huracan-2.png",
        "../../../../monaco_assets/lamborghini-huracan-3.png",
        "../../../../monaco_assets/lamborghini-huracan-4.png",
        "../../../../monaco_assets/lamborghini-huracan-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a lamborghini huracan?",
            answer: "the rental cost varies based on the duration and specifics of your rental agreement. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what are the requirements to rent a lamborghini huracan?",
            answer: "to rent a lamborghini huracan, you must be at least 25 years old, possess a valid driver’s license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 2,
            question: "are there any mileage restrictions when hiring the lamborghini huracan?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "can the lamborghini huracan be delivered to my location?",
            answer: "yes, we offer a delivery and pick-up service for the lamborghini huracan to your specified address within our service areas for an additional fee.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "what happens if the lamborghini huracan is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "where can i find lamborghini rental near me?",
            answer: "monaco experiences provides lamborghini rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Lamborghini rental in NJ,NY,LA by Monaco Experiences 
                </title>
                <meta name="description" content="Rent any Lamborghini Huracan from Monaco Experiences for an extravaganza luxury wedding or special occasion. Contact us for a customised quote." />
                <meta name="keywords" content="lamborghini for rent, lambo rental, rent a lamborghini, rent lamborghini, lamborghini huracan for rent, lamborghini rental, lamborghini rentals" />
                <link rel="canonical" href="https://monacoexperiences.com/lamborghini-huracan-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo4.png" alt="Image" style={{ height: '40px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>LAMBORGHINI HURACAN</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                5.2l v10  |  610 horsepower  |  actual msrp : $ 459,900
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            why is lamborghini rental the most popular in the u.s.?
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            lamborghini rentals have surged to the <span class="fw-700">top in the u.s.</span>, captivating car enthusiasts with their exceptional blend of performance, design, and luxury. monaco experiences, a leader in the exotic car rental industry, offers the lamborghini huracan, a vehicle that promises an unparalleled driving experience.
                        </p>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            the huracan's sleek, aerodynamic lines and bold stance make it a marvel on the road. its y-shaped led headlights add to its distinctive look. the interior features premium materials like leather and alcantara, providing a luxurious feel. the digital dashboard and advanced infotainment system keep you connected and entertained, while the racing-style seats offer maximum comfort. the <span class="fw-700">cockpit-style start/stop button</span> adds a touch of excitement, making you <span class="fw-700">feel like a pilot</span> every time you ignite the engine.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            every detail, from the precisely crafted controls to the exquisite stitching, is designed to enhance your driving experience. with its powerful v10 engine and superior handling, every moment behind the wheel <span class="fw-700">promises to be unforgettable</span>. ready to make your dream a reality?
                        </p>

                        <p class="font-montserrat fw-700 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            hire a lamborghini from monaco experiences today and elevate your adventure 🔥
                        </p>
                    </div>
                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury car rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
