import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { GlobalContext } from '../../../context/GlobalState';
import parse from 'html-react-parser';
import { localRoute } from '../../../routes/localRoutes';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

export default function BlogDetails1() {
    const [blogDetail, setBlogDetail] = useState({});
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { blog_slug } = useParams();
    const history = useHistory();

    console.log("blog_slug blogDetail", blog_slug)

    const handleOpenBlog = (blog_slug) => {
        history.push(`${localRoute.blogDetail}/${blog_slug}`);
        console.log("blog_slug 11", blog_slug)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchBlog = async () => {
            try {
                const response = await fetch(`https://api.monacoexperiences.com/api/v1/articles/${blog_slug}/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setBlogDetail(data?.blog);
            } catch (error) {
                console.error('Error fetching blog:', error);
                // Handle error state or logging here
            }
        };
        fetchBlog();
    }, [blog_slug]);


    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://api.monacoexperiences.com/api/v1/articles/category/blog/?limit=10");
                if (!response.ok) {
                    throw new Error("Failed to fetch blogs");
                }
                const data = await response.json();
                setBlogs(data.results);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);




    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{blogDetail.blog_title && parse(blogDetail.blog_title)}</title>
                <meta name="description" content={blogDetail.meta_description} />
                <meta name="keywords" content={blogDetail.blog_meta_tag} />
                <link rel="canonical" href={`https://monacoexperiences.com${history.location.pathname}`} />
            </Helmet>


            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress style={{ color: '#B88B2B' }} />
                </div>
            ) : (
                <>
                    <div className="mt-120 md-mt-120 font-montserrat container">
                        <div className="col-lg-12">
                            <div className="single-blog-post blog-details-content">
                                <h1 className="font-montserrat cl-white">
                                    {blogDetail.title && parse(blogDetail.title)}
                                </h1>
                                <p className="font-montserrat fs-20 mt-5 fw-400" style={{ color: '#E7E7E7' }}>
                                    {blogDetail.short_body && parse(blogDetail.short_body)}
                                </p>
                                <p className="font-montserrat fs-12 mt-5 fw-300" style={{ color: '#E7E7E7' }}>
                                    {moment(blogDetail.created_at).format('ll')}
                                </p>

                                <img src={blogDetail.cover} className="mt-10" />

                                <p className="font-montserrat fs-18 mt-20 fw-300" style={{ color: '#E7E7E7' }}>
                                    {blogDetail.body && parse(blogDetail.body)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="container text-center">
                        <div className="font-montserrat cl-white">
                            share this blog
                        </div>

                        <h2 class="font-montserrat fs-24 fw-600 mb-20" style={{ color: '#B88B2B' }}>
                            <a style={{ cursor: 'pointer', color: '#fff' }}>
                                trending tags
                            </a>
                        </h2>

                        <div>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>excalibur</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>rolls royce wedding trends</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>lamborghini</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>top 10 exotic rental cars in us</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>photoshoot</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>new wedding trends</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>how much does a ferrari cost?</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>why vintage cars are trending</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>photoshoot</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>what is baarat experience ?</span>
                            <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>perfect budget exotic car</span>
                        </div>

                        <h2 class="font-montserrat fs-24 fw-600 mt-30" style={{ color: '#B88B2B' }}>
                            <a style={{ cursor: 'pointer', color: '#fff' }}>
                                explore other blogs & insights
                            </a>
                        </h2>
                        <hr style={{ background: 'white' }} />

                        <div class="container">
                            <div class="row">

                                {blogs.map(blog => (
                                    <div className="col-lg-4 mt-50" key={blog.id}>
                                        <div onClick={() => handleOpenBlog(blog.slug)} style={{ cursor: 'pointer' }}>
                                            <img src={blog.cover} alt="Blog Thumbnail" />
                                            <div style={{ marginTop: '10px' }}>
                                                <p className="font-montserrat fs-16 fw-300 cl-white">
                                                    {moment(blog.updated_at).format('ll')}
                                                </p>
                                                <p className="font-montserrat fs-22 fw-600 cl-white">{blog.title}</p>
                                                <p className="font-montserrat fs-18 fw-300 cl-white mt-10">{blog.short_body}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
}
