import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";

export default function AstonMartin() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/aston-martin-1.png')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/aston-martin-5.png",
        "../../../../monaco_assets/aston-martin-2.png",
        "../../../../monaco_assets/aston-martin-3.png",
        "../../../../monaco_assets/aston-martin-4.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent an aston martin?",
            answer: "the rental cost varies based on the duration and specifics of your rental agreement. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what are the requirements to rent a lamborghini huracan evo?",
            answer: "to rent an aston martin db11, you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "are there any mileage restrictions when hiring the aston martin?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can the aston martin be delivered to my location?",
            answer: "yes, we offer a delivery and pick-up service to your specified address within our service areas for an additional fee.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the  aston martin is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "can i rent a vintage car for wedding?",
            answer: "yes, you can rent a vintage car for your wedding with monaco experiences. our stunning vintage cars add timeless elegance and sophistication to your special day. also, check out our porsche speedster 1956.",
            isCollapsed: true,
        },
        {
            id: 7,
            question: "where can i find the aston martin rental near me?",
            answer: "monaco experiences offers the rental in multiple locations including la, new york, boston, philadelphia, and more. contact us to find the nearest location for your vintage car rental needs.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Aston Martin Rental in NJ,NY,LA | Monaco Experiences
                </title>
                <meta name="description" content=" Get your customized quote today! Rent Aston Martin for your wedding or any special event from Monaco Experiences. Drive luxury and style. " />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/aston-martin-db11-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-110">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/aston-martin-logo.png" alt="Image" style={{ height: '25px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-16 fw-700" style={{ color: '#fff' }}>ASTON MARTIN DB11</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                v8 | 528 horsepower | 0-60 3.9sec | actual msrp : $236,286
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 5px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>

                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            the ultimate aston martin rental for your next event
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            if you want to enjoy the best of british car craftsmanship, you should drive an aston martin . when you rent an aston martin from monaco experiences, you're not just driving a car; you're joining a history of luxury and performance that goes back more than a hundred years. the db11 is a stunning example of how art and engineering can work together to create something beautiful. it's more than just fast and powerful, it's a journey into beauty.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            it's not just about the thrill of the ride when you hire an aston martin; it's also about who you are. with its hand-stitched leather and cutting-edge technology, the db11's interior is a haven of pure luxury, and its iconic style will make you stand out on the road. this is the perfect car for those important events where only the best will do, like a wedding, or a milestone party.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            when you rent an aston martin db11, you can enjoy the smooth performance and classic style that have made aston martin a global symbol of sophistication. not only is it important to get from a to b, but it's also important to do so with style and class.
                        </p>

                        <p class="font-montserrat fw-600 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            live the bond life, drive aston martin dm11 🔥
                        </p>
                    </div>
                </div>
            </div>

            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury cars rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
