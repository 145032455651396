import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function ExcaliburPheaton1972() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/excalibur-pheaton-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/excalibur-pheaton-2.png",
        "../../../../monaco_assets/excalibur-pheaton-3.png",
        "../../../../monaco_assets/excalibur-pheaton-4.png",
        "../../../../monaco_assets/excalibur-pheaton-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a vintage car?",
            answer: "at monaco experiences, vintage car rental prices vary based on the model and rental duration. contact us for specific pricing details.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what are the requirements to rent a vintage cars?",
            answer: "to rent a vintage car, you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "are there any mileage restrictions when hiring the porsche speedster 1956 ?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can the porsche speedster 1956 be delivered to my location?",
            answer: "yes, we offer a delivery and pick-up service for all the cars to your specified address within our service areas for an additional fee.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the porsche speedster is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "can i rent a vintage car for wedding?",
            answer: "yes, you can rent a vintage car for your wedding with monaco experiences. our stunning vintage cars add timeless elegance and sophistication to your special day. also, check out our excalibur.",
            isCollapsed: true,
        },
        {
            id: 7,
            question: "where can i find the vintage car rental near me?",
            answer: "monaco experiences provides vintage car rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Vintage luxury car rental in NJ,NY,LA | Monaco Experiences
                </title>
                <meta name="description" content="Travel back in time with our Excalibur and Porsche vintage car  from Monaco Experiences for weddings or special occasions. Timeless design and luxury. Contact us for a customised quote." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/excalibur-pheaton-1972-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo10.png" alt="Image" style={{ height: '30px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>EXCALIBUR PHEATON 1972</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                v8  |  400 horsepower  |  actual msrp : $ 59,900
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 5px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            excalibur 1972 : the ultimate vintage car rental
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            indulge in unparalleled sophistication with monaco experiences, your one-stop shop for vintage car rentals.  we proudly present the legendary excalibur 1972, <span class="fw-700">a masterpiece</span> that transcends mere transportation. this <span class="fw-700">iconic car</span> is a rolling piece of history, ready to elevate any occasion, especially your dream wedding.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            imagine a grand entrance on your wedding day that leaves a lasting impression.  picture yourself arriving in the meticulously maintained excalibur 1972 from monaco experiences. heads will turn, cameras will flash, and you'll <span class="fw-700">feel like royalty</span>. this vintage masterpiece exudes timeless elegance, perfectly complementing the significance of your celebration.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            the excalibur 1972 isn't just for weddings! rent a vintage car from monaco experiences for a <span class="fw-700">romantic getaway</span>, a red-carpet event, or simply a <span class="fw-700">luxurious journey</span> down memory lane. we cater to your discerning taste, ensuring every detail is perfect for an unforgettable experience.
                        </p>


                        <p class="font-montserrat fw-700 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            let us make your arrival a lasting impression, no matter the occasion! 🎯
                        </p>
                    </div>
                </div>
            </div>



            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury car rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
