import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticExperiencesNationwide from "components/home/exoticExperiencesNationwide";
import ExoticRentalCar from "components/home/exoticRentalCar";
import AskQuestion from "components/home/askQuestion";
import DriveTheDream from "components/home/driveTheDream/DriveTheDream";
import ExoticRental16Car from "components/home/exoticRental16Car";
import LogoScrolling from "components/home/whyChooseUs/LogoScrolling";

export default function NewJersey(props) {

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of luxury cars are available for rent in nj?",
            answer: "we offer a wide range of luxury cars, including lamborghini huracan, ferrari f8 spyder, rolls royce dawn, bentley gtc, mclaren and more to check our full exotic cars fleet click here",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "why moncao experiences is the best exotic car rental services in new jersey?",
            answer: "at monaco experiences, we offer top-notch exotic car rental services in los angeles, featuring luxury brands like lamborghini, ferrari, rolls royce, and more. our exceptional customer service and premium car selection makes the best choice for your exotic car rental needs.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "do you provide baraat packages in new jersey?",
            answer: "yes, we provide specialized baraat packages in new jersey. our unique baraat experience mobile van is equipped with a tv, smoke gun, dj, lights, and a beer/wine tank, perfect for indian weddings. contact us to customize your baraat package.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can i get a luxury car rental for a wedding in new jersey?",
            answer: "yes, we offer luxury car rentals for weddings in new jersey to make your special day extraordinary. choose from our wide selection of luxury cars to add a touch of elegance to your wedding. check our full exotic cars fleet here",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Exotic,Luxury & Vintage Car Hire in NJ by Monaco Experiences 
                </title>
                <meta name="description" content="Based in New Jersey, Monaco Experiences has served over 1000 car enthusiasts with our exceptional car rental services. Experience the thrill of driving your dream car today! Book now." />
                <meta name="keywords" content="exotic car rental for wedding in nj, luxury car rental for wedding in nj , exotic car rental new jersey, Luxury car rental new jersey " />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-new-jersey" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/new-jersey.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '85vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                minWidth: '100%',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental nj</h1>
                            <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>
                </div>

                <div style={{ background: '#111' }}>

                    <div class="container pt-30 pb-30">
                        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-12 mb-10">
                                <h2 class="font-montserrat fs-30 fw-800 text-center" style={{ color: '#fff' }}>
                                    unleash the jersey shore in style: rent an exotic car with monaco experiences
                                </h2>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                <span className="fw-700">searching for luxury car rentals in new jersey?</span> look no further! new jersey's diverse landscapes, from the vibrant shores of atlantic city to the charming streets of hoboken, deserve an unforgettable driving experience.  turn heads or capture breathtaking visuals with a head-turning exotic car rental from monaco experiences.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                we specialize in transforming your new jersey vision of driving a luxury car into reality. our meticulously maintained fleet boasts an impressive selection of the <span className="fw-700">world's most coveted supercars</span> and <span className="fw-700">luxury vehicles</span>. craving the raw power of a <span className="fw-700">lamborghini huracan</span> or the timeless elegance of a <span className="fw-700">rolls royce dawn</span>? we have the perfect set of wheels to elevate your journey, no matter the season.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                hiring an exotic car isn't just about the vehicle; it's about the experience. <span className="fw-700">surprise your loved one</span> with a romantic getaway in a mclaren 720s. celebrate a milestone with a night out in a <span className="fw-700">show-stopping ferrari</span>. even a casual sunday drive along the garden state parkway becomes an extraordinary event behind the wheel of an exotic masterpiece.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                at monaco experiences, <span className="fw-700">exceptional customer service is our priority!</span> our dedicated team ensures a seamless rental process, allowing you to focus on the pure pleasure of driving. <span className="fw-700">don't settle for ordinary</span>. make your next photoshoot, wedding, music video unforgettable with an exotic car rental from monaco experiences in new jersey.  discover the thrill of the open road in <span className="fw-700">absolute luxury</span>.
                            </p>
                        </div>
                    </div>
                </div>

                <LogoScrolling />

                <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                    <div class="container section-heading text-center">
                        <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                            available exotic cars in nj
                        </h2>
                        <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                            <span style={{ fontWeight: 700 }}>classy and luxury</span> rental cars in new jersey : discover our unique showroom experience
                        </p>
                    </div>
                    <ExoticRental16Car extraText={"RENTAL IN NJ"} />
                </div>


                <DriveTheDream extraText={"in nj"} />
                <StoriesFromCustomer />
                

                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>


            </div>
        </div>

    )
}
